import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Candidature } from "./Candidature/Form"
import { default as Documentation } from "./Documentation/Form"
import Forms from "../Forms"
import { customTheme } from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import { StepProvider } from "./Candidature/useStepForm"
import stepConfigurations from "./Candidature/step-configurations"

const CandidatureAdvance = ({ cycle = '', formation = '', keyform = '', primarycolor = '', school = '' }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <StepProvider keyform={keyform} initialConfig={stepConfigurations}>
            <Candidature defaultCycle={cycle} formation={formation} keyform={keyform} school={school} />
          </StepProvider>
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationAdvance = ({ elevation, primarycolor }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation}>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { CandidatureAdvance, DocumentationAdvance }
